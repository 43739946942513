
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';


class CustomSlide extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        const { t } = this.props;
        return (

            < div className="more-cars-slider">
                <h3>{t('Luxury.1')}</h3>
                <span>Quisque velit nisi, pretium ut lacinia in, elementum id enim. Vestibulum ac diada pretium ut lacinia in, elementum id enim.</span>
                <img

                    src={this.props.vehicleType.Attachment.ImageString}
                    alt="First slide" style={{ width: "572", height: "282" }}
                />
                <div className="more-car-bttns1">
                    <a className="primary-bttn" title="View More" onClick={() => { this.onViewMoreClick(this.props.vehicleType.VehicleClassificationID) }} >
                        {t('View More.1')}
                    </a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a className="primary-bttn primary-invert-bttn" title="Book Now" onClick={() => { this.onSeeAllCarsClick(this.props.vehicleType.VehicleClassificationID) }}>
                        {t('Book Now.1')}
                    </a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a className="primary-bttn" title="See All Cars" onClick={() => { this.onSeeAllCarsClick(this.props.vehicleType.VehicleClassificationID) }}>
                        {t('See All Cars.1')}
                    </a>
                </div>
            </div>

        );
    }
}

export default withTranslation()(withRouter(CustomSlide));