
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
const { htmlToText } = require('html-to-text');



class NewsTile extends Component {
    constructor(props) {
        super(props);
       
    }

    onNewsClick=(news)=>{ 
        if(news.SourceUrl != "" && news.SourceUrl!='')
        {
            window.open(news.SourceUrl, '_blank');
        }
       else
       {
        this.props.history.push(`/news/${news.NewsID}`);
       }
    }

    render() {
        const { t } = this.props;
          return (
            <div className="col-lg-4 col-md-6 col-12" >
            <div className="what-new-box" onClick={()=>this.onNewsClick(this.props.news)}>
                
                <img src={this.props.news.ThumbnailAttachment.ImageString} alt="Third Article" />
               
                <div className="what-new-box-more-content" >
                    <p>
                        {htmlToText(this.props.news.Title).toString().substring(0, 94)}
                    </p>
                    <a className="what-new-link read-more-link" onClick={()=>this.onNewsClick(this.props.news)}>{t('Read More.1')}</a>
                </div>
            </div>
        </div>
          );
    }
}

export default withTranslation()(withRouter(NewsTile)); 