
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class SpecialOfferTile extends Component {

    onSpecialOfferLinkClick = (ID) => {
        this.props.history.push(`/offers/${ID}`);
    }

    render() {
        const { t } = this.props;
          return (
        //     <div className="col-md-4 col-sm-6 col-12" >
        //     <div className="special-offer-box">
        //         <div className="special-offer-box-wrap">
        //             <a href="#" className="special-offer-link"></a>
        //             {(this.props.offers.FavouriteNote != '') ? <div className="discount-sticker">{this.props.offers.FavouriteNote}</div> : ""}
        //             <span className="special-offer-image" style={{ background: `url(${this.props.offers.ThumbnailAttachment.ImageString})` }}>
        //             </span>
        //             <a className="special-offer-link" onClick={() => this.onSpecialOfferLinkClick(this.props.offers.SpecialOfferID)}>
        //                 <figcaption className="special-offer-figcaption">
                            
        //                     <p className="special-offer-content">
        //                         <i className="">{this.props.offers.Name.substring(0, 26)}</i>
        //                         <i className="fa fa-angle-right" aria-hidden="true"></i>
        //                     </p>
        //                 </figcaption> </a>
        //         </div><br />
        //     </div>
        // </div>
            <div className='new-more-car-slider-box-special-offer'  onClick={() => { this.onSpecialOfferLinkClick(this.props.offers.SpecialOfferID) }}>
                <div className='new-more-car-slider-img'>
                    <LazyLoadImage src={this.props.offers.ThumbnailAttachment.ImageString} alt="First slide" />
                </div>
                <div className='new-more-car-slider-content'>
                    {/* <p>{offers.Name}</p> */}
                    <p>{this.props.offers.Name}<span>{this.props.offers.FavouriteNote}</span></p>
                    <a className="what-new-link new-link" title={t('READ MORE.1')} onClick={() => { this.onSpecialOfferLinkClick(this.props.offers.SpecialOfferID) }} >
                    {t('READ MORE.1')}
                    </a> 
                </div>
            </div>
          );
    }
}

export default withTranslation()(withRouter(SpecialOfferTile)); 